
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

#ServiceCard{
    height: 400px;
    background: rgba(0, 0, 0, 0.918);
    color: white;
    border-radius: 20px;
    transition: border 0.3s ease, background 0.2s ease; /* Combine transitions */
}
#ServiceCard:hover{
    border: 2px solid white;
    background: rgb(9, 12, 21);
    
}

#cardButton{
    background: rgb(56, 147, 207);
    color: rgb(255, 255, 255);
    border-radius: 20px;
    width: 25%;
    height: 8%;
    text-align: center;
    font-weight:bold ;
}
#cardserviceText{
    font-size: 13px;
    color: #a8a8a8;
}
#cardHeadingText{
    transition: color 0.2s ease;
}
#ServiceCard:hover #cardHeadingText
{
    color: rgb(56, 147, 207);
}

#cardServiceImg{
    width: 30px !important;
    height: 30px !important;
    margin-left:20px;
    margin-top:10px;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@media (min-width: 1440px) {
  html {
    zoom: 1.5;
  }
}

@media (min-width: 2560px) {
  html {
    zoom: 1.7;
  }
}

@media (min-width: 3860px) {
  html {
    zoom: 2.5;
  }
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

#servicesContainer {
  font-size: 1.6rem !important;
  background: var(--bg) !important;
}

#servicesContainer {
  max-width: 124rem !important;
  padding: 4rem 1rem !important;
  margin: 0 auto !important;

}
#MainServicesContainer
{
    background-color: rgb(0, 0, 0);
}

.swiper_container {
  height: 52rem !important;
  padding: 2rem 0 !important;
  position: relative !important;
}

.swiper-slide {
  width: 37rem !important;
  height: 42rem !important;
  position: relative !important;
}

@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}

.swiper-slide img {
  width: 37rem !important;
  height: 42rem !important;
  border-radius: 2rem !important;
  object-fit: cover !important;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none !important;
}

.slider-controler {
  position: relative !important;
  bottom: 2rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white) !important;
  width: 3.5rem !important;
  height: 3.5rem !important;
  border-radius: 50% !important;
  left: 42% !important;
  transform: translateX(-42%) !important;
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1)) !important;
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem !important;
  color: #222224 !important;
}

.slider-controler .slider-arrow::after {
  content: '' !important;
}

.swiper-pagination {
  position: relative !important;
  width: 15rem !important;
  bottom: 1rem !important;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  background: #ffffff;

}

.swiper-pagination .swiper-pagination-bullet-active {
  background: rgb(56, 147, 207);
}


/* Extra Large Screens (1200px and above) */
@media (min-width: 1200px) {
    #ServiceCard #cardServiceImg {
        height: 50px;
        width: 50px;
    }
    #ServiceCard #cardHeadingText {
        font-size: 2rem;
    }
    #ServiceCard #cardserviceText {
        font-size: 1.2rem;
    }
    #ServiceCard #cardButton {
        font-size: 1.1rem;
    }
}

/* Extra Large Screens (1200px and above) */
@media (min-width: 1200px) {
    #cardServiceImg {
        height: 50px;
        width: 50px;
    }
    #cardHeadingText {
        font-size: 2rem;
    }
    #cardserviceText {
        font-size: 1.2rem;
    }
    #cardButton {
        font-size: 1.1rem;
    }
}

/* Large Screens (992px - 1199px) */
@media (max-width: 1199px) {
    #cardServiceImg {
        height: 40px;
        width: 40px;
    }
    #cardHeadingText {
        font-size: 1.9rem;
    }
    #cardserviceText {
        font-size: 1.3rem;
    }
    #cardButton {
        font-size: 1.2rem;
    }
}

/* Medium Screens (768px - 991px) */
@media (max-width: 991px) {
    #cardServiceImg {
        height: 35px;
        width: 35px;
    }
    #cardHeadingText {
        font-size: 1.7rem;
    }
    #cardserviceText {
        font-size: 1.3rem;
    }
    #cardButton {
        font-size: 1.2rem;
    }
}

/* Small Screens (576px - 767px) */
@media (max-width: 767px) {
    #cardServiceImg {
        height: 30px;
        width: 30px;
    }
    #cardHeadingText {
        font-size: 1.5rem;
    }
    #cardserviceText {
        font-size: 1.2rem;
    }
    #cardButton {
        font-size: 1.1rem;
    }
}

/* Extra Small Screens (up to 575px) */
@media (max-width: 575px) {
    #cardServiceImg {
        height: 25px;
        width: 25px;
    }
    #cardHeadingText {
        font-size: 1.5rem;
    }
    #cardserviceText {
        font-size: 1.2rem;
    }
    #cardButton {
        font-size: 1.1rem;
    }
}

/* For screens below 500px width and 800px height */
@media (max-width: 500px) {
    #cardServiceImg {
        height: 20px; /* Smaller size */
        width: 20px;
    }
    #cardHeadingText {
        font-size: 1.3rem; /* Smaller heading */
    }
    #cardserviceText {
        font-size: 1.0rem; /* Smaller paragraph text */
    }
    #cardButton {
        font-size: 1rem; /* Smaller button text */
    }
}