.s1{
    background-color: rgb(0, 0, 0);
    height: 800px;
    background-image: url("../../assets/images/Bghomedark.png");
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating the image */
    color: white; /* Optional: Change text color for contrast */
   top: 0;
    
}


.Text{
    width: 70%;
    height: 100%;
    padding-top: 25vh;
    align-items:flex-start;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

}
.buttonContact{
    padding-top: 10px;
   
}
.ContactButton{
    height: 50px;
    width: 160px;
    background-color: rgb(56, 147, 207);
    color: rgb(255, 255, 255);
    font-size: 17px;
    font-weight:bold;
    border: 1px solid black;
    border-radius: 40px;
}

/* Adjust for small screens */
@media (max-width: 768px) {
    div.Texts1 {
      padding-left: 20px; /* Reduce padding on smaller screens */
      padding-right: 20px;
    }
  
    .Text .h1 {
      font-size: 1.7rem; /* Reduce font size for h1 */
    }
  
    .Text .h5 {
      font-size: 1.3rem; /* Reduce font size for h5 */
    }
    .ContactButton{
        height: 30px;
        width: 120px;
        font-size: 12px;
    }
  }
  


  /* Extra small screens */
@media (max-width: 480px) {
    
    .Text .h1 {
      font-size: 1.5rem; /* Further reduce font size for very small screens */
    }
  
    .Text .h5 {
      font-size: 1.1rem; /* Further reduce font size */
    }

    .ContactButton{
        height: 30px;
        width: 100px;
        font-size: 9px;
    }
  }