.nav-item {
    margin-right: 60px;  /* Adjust spacing as needed */
  }
  
.custom-navbar {
    height: 100px;
    top: 0;
    width: 100%;
    background-color: #000000;
    color: rgb(255, 255, 255);
    
  }
  .navbar-collapse
  {
    background-color: #000000;
    width: 100%;
    padding-top: 0 auto;
    margin-top: 10px;
    top: 0;
    color: rgb(255, 255, 255);
    
    border-radius: 20px;
  }
 
  .navbar-brand{
   color:"whitesmoke";
  }

  .navbar-toggler {
    background-color: white; /* Set background color */
    border: 1px solid black; /* Set border color */
  }
  
  /* Change the icon color inside the toggle button */
  .navbar-toggler-icon {
    filter: brightness(0) invert(1); /* Makes the icon black or white depending on background */
  }