.Appdiv{
    background-color: black;
}
.s5{
    height: autO;
    background-color: rgb(0, 0, 0);
}
.cs5{
    width: 70% !important ;
    height: auto;

}

.workCard{
    background-color: black !important;
}
.textColor{
    color: white !important;
}

.cardFooterText{
    color: rgb(167, 166, 166);
}