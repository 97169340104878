.s4{
    background-color: rgb(0, 0, 0);
    height:auto;
}
div.Texts4{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 40px;
    padding-top: 1vh;
    margin: 0 auto;
    text-align: center; /* Center-align text for better mobile view */
     width: 100%;
   }

#p4Text{
    color: rgba(255, 255, 255, 0.756);
}


.buttonContacts4{
    padding-top: 10px;
    margin-bottom: 15px;
   
}
.ContactButtons4{
    height: 35px;
    width: 130px;
    background-color: rgb(56, 147, 207);
    color: rgb(255, 255, 255);
    font-size: 10px;
    font-weight:bold;
    border: 1px solid black;
    border-radius: 40px;
    
}

/* Adjust for small screens */
@media (max-width: 768px) {
    div.Texts4 {
      padding-left: 20px; /* Reduce padding on smaller screens */
      padding-right: 20px;
    }
  
    .Texts4 .h1 {
      font-size: 1.5rem; /* Reduce font size for h1 */
    }
  
    .Texts4 .h5 {
      font-size: 1rem; /* Reduce font size for h5 */
    }
    .ContactButtons4{
        height: 30px;
        width: 120px;
        font-size: 8px;
    }
  }
  


  /* Extra small screens */
@media (max-width: 480px) {
    div.Texts4 {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 2vh; /* Adjust padding to fit smaller viewports */
    }
  
    .Texts4 .h1 {
      font-size: 1.2rem; /* Further reduce font size for very small screens */
    }
  
    .Texts4 .h5 {
      font-size: 0.9rem; /* Further reduce font size */
    }

    .ContactButtons4{
        height: 30px;
        width: 80px;
        font-size: 6px;
    }
  }