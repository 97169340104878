.wd1{
    background-color: rgb(0, 0, 0);
    height: 500px;

    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating the image */
    color: white; /* Optional: Change text color for contrast */
   top: 0;
}
.Textwd1{
    width: 70%;
    height: 100%;
    padding-top: 25vh;
    align-items:center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.workflowHeading
{
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: 0 auto;
    width: 70%;
    height: 50px;
    color: white !important;
    padding-top: 10px;
}
.stepscontainerCol{
    background-color: rgb(27, 31, 43);
    height: auto !important;
    border: 2px solid white;
    border-radius: 20px;

}

.steprow{
    padding-top: 20px;
    padding-bottom: 10px;
}
.StepsMainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

}
.DropHeader{
    background-color: rgb(27, 31, 43) !important;
    color: white !important;
    font-size: 12px;

}
.accordion-button{
    background-color: rgb(14, 14, 14) !important;
    color: white !important;
    border-color: red !important;
}