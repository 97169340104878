.s2{
    background-color: rgb(0, 0, 0);
    height:150px ;
}

div.Texts2{
 display: flex;
 align-items: center;
 flex-direction: column;
 padding-left: 50px;
 padding-top: 3vh;
 margin: 0 auto;
 text-align: center; /* Center-align text for better mobile view */
  width: 100%;
}

#pText{
    color: rgba(255, 255, 255, 0.756);
}
/* Adjust for small screens */
@media (max-width: 768px) {
    div.Texts2 {
      padding-left: 20px; /* Reduce padding on smaller screens */
      padding-right: 20px;
    }
  
    .Texts2 .h1 {
      font-size: 1.5rem; /* Reduce font size for h1 */
    }
  
    .Texts2 .h5 {
      font-size: 1rem; /* Reduce font size for h5 */
    }
  }
  
  /* Extra small screens */
@media (max-width: 480px) {
    div.Texts2 {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 2vh; /* Adjust padding to fit smaller viewports */
    }
  
    .Texts2 .h1 {
      font-size: 1.2rem; /* Further reduce font size for very small screens */
    }
  
    .Texts2 .h5 {
      font-size: 0.9rem; /* Further reduce font size */
    }
  }